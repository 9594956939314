/*custom css*/
.custom-scroll {
    cursor: pointer;
    max-height: 400px;
    height: 400px;
    margin-bottom: 25px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
        cursor: pointer;
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border: 10px solid #c0c0c0;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #b3b3b3;
    }
}
.gallery-img img{
    margin-left: 0 !important;
    border-radius: 0.25rem;
    object-fit: cover;
}
.ReactGridGallery_tile-overlay{
    backdrop-filter: grayscale(100%);
}